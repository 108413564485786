import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import app from './app/reducers';
import auth from './auth/reducers';
// import Role from '../layouts/auth/Redux/Role/reducers';

export default combineReducers({
  toastr: toastrReducer,
  app,
  auth,
  // role: Role,
});
