import async from '../components/atoms/async';

const HomePage = async(() => import('src/components/pages/home'));

const authRoutes = [
  {
    auth: true,
    path: '/',
    key: '/',
    layout: 'sidebar',
    component: HomePage,
  },
];

export default authRoutes;
