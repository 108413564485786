import async from '../components/atoms/async';

const User = async(() => import('src/components/pages/user-new'));
const UserEdit = async(() => import('src/components/pages/user-edit-new'));
const UserCreate = async(() => import('src/components/pages/user-create-new'));

const manageUsers = [
  {
    auth: true,
    path: '/user',
    key: 'settings.manage-users.list',
    layout: 'sidebar',
    component: User,
  },
  {
    auth: true,
    path: '/user/add',
    key: 'settings.manage-users.create',
    layout: 'sidebar',
    component: UserCreate,
    title: 'create_user',
  },
  {
    auth: true,
    path: '/user/edit/:user_id',
    key: 'settings.manage-users.edit',
    layout: 'sidebar',
    component: UserEdit,
    title: 'edit_user',
  },
];

export default manageUsers;
