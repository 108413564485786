const constant = {
  token: 'remid_token',
  uid: 'uid',
};

export const saveToken = (value: string) => {
  localStorage.setItem(constant.token, value);
};

export const saveUid = (value: string) => {
  localStorage.setItem(constant.uid, value);
};

export const getToken = () => localStorage.getItem(constant.token);

export const getUid = () => localStorage.getItem(constant.uid);

export const removeToken = () => {
  localStorage.removeItem(constant.token);
};

export const removeUid = () => {
  localStorage.removeItem(constant.uid);
};
