import async from '../components/atoms/async';

const Role = async(() => import('src/components/pages/role'));
const RoleDetail = async(() => import('src/components/pages/role-detail'));
const RoleCreate = async(() => import('src/components/pages/role-create'));

const roleRoutes = [
  {
    auth: true,
    path: '/',
    role: [],
    layout: 'sidebar',
    component: null,
  },
  {
    auth: true,
    path: '/role',
    key: 'settings.manage-role.list',
    layout: 'sidebar',
    component: Role,
  },
  {
    auth: true,
    path: '/role/user',
    key: 'settings.manage-role.list',
    layout: 'sidebar',
    component: Role,
  },
  {
    auth: true,
    path: '/role/create',
    key: 'settings.manage-role.create',
    layout: 'sidebar',
    component: RoleCreate,
    title: 'create_role',
  },
  {
    auth: true,
    path: '/role/detail/:role_id',
    key: 'settings.manage-role.list',
    layout: 'sidebar',
    component: RoleDetail,
    title: 'detail_role',
  },
];

export default roleRoutes;
